/* This example requires Tailwind CSS v2.0+ */
import React, { useCallback, useEffect } from "react";
import { useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";

import Button from "../Button";
import { useEvent } from "../../../Store/event";
import "./DownloadForms.css";
import {
  foldersDownload,
  favouriteDownload,
  faceSearchDownload,
} from "../../../apis/download";
import { useUser } from "../../../Store/user";
import { useUploader } from "../../../Store/upload";
import { faecResults } from "../../../apis/facesearch";
import { useFolder } from "../../../Store/folder";

export default function DownloadForms({
  showFavouriteImages,
  showFaceSearchImages,
  close,
  listId = "",
}) {
  let { user } = useUser();
  let eventDetails = useEvent().response;
  const { myPhotos, setMyPhotos } = useFolder();
  const params = useParams();
  const [selectedFolders, setSelectedFolders] = useState(
    emptyFolderImagesCheck(eventDetails.data.folders)
  );
  const [downloadSize, setDownloadSize] = useState(
    eventDetails.data.downloadBulkImageSize == "optimized" ? 1 : 0
  );
  const [error, setError] = useState("");
  const [isDisabled, setDisable] = useState(true);
  const [loader, setLoader] = useState(false);
  const [myPhotosLoader, setMyPhotosLoader] = useState(false);

  const { guestUploadedImages } = useUploader();

  let ref = useRef();

  function emptyFolderImagesCheck(data) {
    return data
      .map((element) => {
        if (
          (user.isAdmin ? element.imagesCount : element.publicImagesCount) &&
          element.isDownloadAble &&
          (user.isAdmin ? true : !!!element.showOnlyOnFaceSearch)
        ) {
          return element.id;
        }
      })
      .filter((notUndefined) => notUndefined !== undefined);
  }

  const handleOnChange = useCallback(
    (e) => {
      if (!e.target.checked)
        setSelectedFolders(
          selectedFolders.filter((f) => Number(f) !== Number(e.target.value))
        );
      else setSelectedFolders(selectedFolders.concat([Number(e.target.value)]));
    },
    [selectedFolders]
  );
  const handleOnChangeAll = useCallback(
    (e) => {
      if (!e.target.checked) setSelectedFolders([]);
      else
        setSelectedFolders(emptyFolderImagesCheck(eventDetails.data.folders));
    },
    [selectedFolders]
  );

  useEffect(() => {
    setDisable(true);
    if (selectedFolders.length) {
      setDisable(false);
    }
  }, [selectedFolders]);

  const getFaceSearchResult = async () => {
    try {
      const res = await faecResults(user.search_id, eventDetails.data.id);
      if (!res.error) {
        if (!res.response.allImagesPrivate) {
          setMyPhotos(res.response.data);
        }
      } else throw new Error(res.error);
    } catch (error) {
      console.error("Error");
    }
  };

  useEffect(() => {
    if (
      !myPhotos.length &&
      !showFaceSearchImages &&
      !showFavouriteImages &&
      user.search_id
    ) {
      getFaceSearchResult();
    }
  }, []);

  const submitDownload = async () => {
    setLoader(true);
    if (eventDetails.data.downloadBulkImageSize == "original") {
      setDownloadSize(0);
    }
    if (eventDetails.data.downloadBulkImageSize == "optimized") {
      setDownloadSize(1);
    }
    let res;
    if (showFavouriteImages) {
      res = await favouriteDownload(
        eventDetails.data.id,
        user.email,
        Number(downloadSize),
        listId ? listId : params.listId,
        user.accessToken,
        "favourites"
      );
    } else if (showFaceSearchImages) {
      res = await faceSearchDownload(
        eventDetails.data.id,
        user.email,
        Number(downloadSize),
        user.search_id,
        user.accessToken,
        "face_search"
      );
    } else {
      res = await foldersDownload(
        eventDetails.data.id,
        selectedFolders,
        Number(downloadSize),
        user.accessToken,
        user.email,
        "gallery"
      );
    }
    if (!res.error) {
      window.Toast.fire({
        icon: "success",
        title: res.response.message
          ? res.response.message
          : "Download in process, please wait for it to complete you wil receive an email shortly.",
      });
      localStorage.setItem("downloadInitiatedFrom", window.location.href);
    } else {
      window.Toast.fire({
        icon: "error",
        title: res.e.message[0],
      });
    }
    if (res.response.downloadRequestId) {
      // let url = window.location.protocol + '//' +window.location.host+'/downloadNow/'+eventDetails.data.slug+'/'+Base64.encode(res.response.dl.toString())
      try {
        const url =
          window.location.protocol +
          "//" +
          window.location.host +
          "/downloadNow/" +
          eventDetails.data.slug +
          "/" +
          res.response.downloadRequestId;
        window.open(url, "_blank").focus();
      } catch (error) {
        console.log("Issue while opening browser in new window", error);
      }
    }
    close(false);
    setLoader(false);
  };

  const downloadFaceSearchImages = async () => {
    setMyPhotosLoader(true);
    if (eventDetails.data.downloadBulkImageSize == "original") {
      setDownloadSize(0);
    }
    if (eventDetails.data.downloadBulkImageSize == "optimized") {
      setDownloadSize(1);
    }
    try {
      const res = await faceSearchDownload(
        eventDetails.data.id,
        user.email,
        Number(downloadSize),
        user.search_id,
        user.accessToken,
        "face_search"
      );
      if (!res.error) {
        window.Toast.fire({
          icon: "success",
          title: res.response.message
            ? res.response.message
            : "Download in process, please wait for it to complete you wil receive an email shortly.",
        });
        localStorage.setItem("downloadInitiatedFrom", window.location.href);
      } else {
        window.Toast.fire({
          icon: "error",
          title: res.e.message[0],
        });
      }
      if (res.response.downloadRequestId) {
        const url =
          window.location.protocol +
          "//" +
          window.location.host +
          "/downloadNow/" +
          eventDetails.data.slug +
          "/" +
          res.response.downloadRequestId;
        window.open(url, "_blank").focus();
      }
    } catch (error) {
      console.error(error);
    } finally {
      close(false);
      setMyPhotosLoader(false);
    }
  };

  const downloadGuestUpload = async () => {
    setMyPhotosLoader(true);
    if (eventDetails.data.downloadBulkImageSize == "original") {
      setDownloadSize(0);
    }
    if (eventDetails.data.downloadBulkImageSize == "optimized") {
      setDownloadSize(1);
    }
    try {
      const res = await foldersDownload(
        eventDetails.data.id,
        [guestUploadedImages[0].folderId],
        Number(downloadSize),
        user.accessToken,
        user.email,
        "gallery"
      );
      if (!res.error) {
        window.Toast.fire({
          icon: "success",
          title: res.response.message
            ? res.response.message
            : "Download in process, please wait for it to complete you wil receive an email shortly.",
        });
        localStorage.setItem("downloadInitiatedFrom", window.location.href);
      } else {
        window.Toast.fire({
          icon: "error",
          title: res.e.message[0],
        });
      }
      if (res.response.downloadRequestId) {
        const url =
          window.location.protocol +
          "//" +
          window.location.host +
          "/downloadNow/" +
          eventDetails.data.slug +
          "/" +
          res.response.downloadRequestId;
        window.open(url, "_blank").focus();
      }
    } catch (error) {
      console.error(error);
    } finally {
      close(false);
      setMyPhotosLoader(false);
    }
  };

  return (
    <div className="mt-2 bg-theme-light dark:bg-dark-gray text-dark-gray1 dark:text-light-gray align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:mt-8 sm:align-middle sm:max-w-4xl sm:w-full">
      <form ref={ref}>
        <div className="text-red-500">{error}</div>
        {!showFavouriteImages && !showFaceSearchImages && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 pt-3 break-all">
            <div className="flex items-center">
              <label
                className={`select-none cursor-pointer flex items-center text-xs md:text-base font-semibold text-cyan-600 underline`}
                htmlFor="selectAll"
              >
                <input
                  className={`form-check-input mr-5 ${
                    selectedFolders.length ===
                    emptyFolderImagesCheck(eventDetails.data.folders).length
                      ? "h-4 accent-cyan-600 rounded min-w-[16px]"
                      : "appearance-none  w-[16px] border border-theme-light dark:border-dark-gray bg-theme-light dark:bg-dark-gray dark:shadow-dark-inner-all shadow-light-checkbox accent-blue focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain cursor-pointer h-4 accent-cyan-600 rounded "
                  }`}
                  type="checkbox"
                  name="folders"
                  id="selectAll"
                  checked={
                    selectedFolders.length ===
                    emptyFolderImagesCheck(eventDetails.data.folders).length
                      ? true
                      : false
                  }
                  onChange={handleOnChangeAll}
                  aria-describedby="select-all"
                />
                {selectedFolders.length ===
                emptyFolderImagesCheck(eventDetails.data.folders).length
                  ? "Deselect"
                  : "Select"}{" "}
                All
              </label>
            </div>
            {eventDetails.data.folders
              .map((f) => {
                if (
                  (user.isAdmin ? f.imagesCount : f.publicImagesCount) &&
                  f.isDownloadAble &&
                  (user.isAdmin ? true : !!!f.showOnlyOnFaceSearch)
                ) {
                  return (
                    <div key={f.id} className="flex items-center">
                      <label
                        className={`text-dark-gray1 select-none cursor-pointer dark:text-light-gray flex items-center text-dark-gray1 text-xs md:text-base`}
                        htmlFor={f.id}
                      >
                        <input
                          className={`form-check-input mr-5 ${
                            selectedFolders.indexOf(f.id) > -1
                              ? "h-4 accent-cyan-600 rounded min-w-[16px]"
                              : "appearance-none h-[16px] w-[16px] border border-theme-light dark:border-dark-gray bg-theme-light dark:bg-dark-gray dark:shadow-dark-inner-all shadow-light-checkbox accent-blue focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain cursor-pointer h-4 accent-cyan-600 rounded "
                          }
                                            ${
                                              f.name.length > 35
                                                ? "md:translate-y-[-75%]"
                                                : ""
                                            }`}
                          type="checkbox"
                          name="folders"
                          id={f.id}
                          checked={
                            selectedFolders.indexOf(f.id) > -1 ? true : false
                          }
                          onChange={handleOnChange}
                          value={f.id}
                          aria-describedby={f.id}
                        />
                        {f.name.replace(/-/g, " ").toUpperCase()}
                        <span
                          className={`text-[8px] md:text-xs text-dark-gray1 ml-5 dark:text-[#eeeeee] ${
                            f.name.length > 35 ? "md:translate-y-[0%]" : ""
                          }`}
                        >
                          {user.isAdmin ? f.imagesCount : f.publicImagesCount}{" "}
                          images
                        </span>
                      </label>
                      {/* <label for="checkbox-2" className="ml-3 text-xs">2019 images</label> */}
                    </div>
                  );
                }
              })
              .filter((notUndefined) => notUndefined !== undefined)}
          </div>
        )}
        {eventDetails.data.downloadBulkImageSize == "both" && (
          <>
            <div className="flex flex-row grow pb-4 pt-9 text-xs md:text-base text-dark-gray1 dark:text-light-gray">
              Choose download size
            </div>
            <div className="flex">
              <div
                className={`flex items-center mr-5 rounded-[10px] p-3 mb-5 ${
                  !downloadSize
                    ? "shadow-inner shadow-light-download-button dark:shadow-dark-inner-all"
                    : "shadow-dark-input-bottom-right dark:shadow-dark-input"
                } w-[130px] md:w-[10.188rem] md:h-[2.75rem] h-[46px]`}
              >
                <input
                  id="radio-1"
                  type="radio"
                  value={0}
                  checked={!downloadSize ? true : false}
                  onChange={() => {
                    setDownloadSize(0);
                  }}
                  name="image_type"
                  className={`w-5 h-4 ${
                    !downloadSize
                      ? ""
                      : "shadow-light-download-radio-button dark:shadow-dark-download-radio-button"
                  } accent-cyan-600 downloadRadioButton`}
                />
                <label
                  htmlFor="radio-1"
                  className="ml-3 text-[10px] md:text-sm cursor-pointer w-full h-full text-dark-gray1 dark:text-light-gray flex items-center"
                >
                  High Resolution
                </label>
              </div>
              <div
                className={`flex items-center mr-5 rounded-[10px] p-3 mb-5 ${
                  downloadSize
                    ? "shadow-inner dark:shadow-dark-inner-all"
                    : "shadow-dark-input-bottom-right dark:shadow-dark-input"
                } w-[130px] md:w-[10.188rem] md:h-[2.75rem] h-[46px]`}
              >
                <input
                  id="radio-2"
                  name="image_type"
                  value={1}
                  checked={!downloadSize ? false : true}
                  onChange={() => {
                    setDownloadSize(1);
                  }}
                  aria-describedby="radio-2"
                  type="radio"
                  className={`w-5 h-4 accent-cyan-600 downloadRadioButton rounded ${
                    !downloadSize
                      ? "dark:shadow-dark-download-radio-button shadow-light-download-radio-button"
                      : ""
                  }`}
                />
                <label
                  htmlFor="radio-2"
                  className="ml-3 text-[10px] md:text-sm cursor-pointer w-full h-full text-dark-gray1 dark:text-light-gray flex items-center"
                >
                  Web Size
                </label>
              </div>
            </div>
          </>
        )}
        <div className="flex justify-center pb-4 pt-4 gap-8">
          {!showFaceSearchImages && (
            <Button
              disabled={isDisabled || loader || myPhotosLoader}
              text={loader ? "Waiting..." : "Download"}
              onClick={submitDownload}
            />
          )}
          {showFaceSearchImages && !!user.images.length ? (
            <Button
              disabled={isDisabled || loader || myPhotosLoader}
              text={loader ? "Waiting..." : "Download My Photos"}
              onClick={submitDownload}
            />
          ) : (
            <></>
          )}
          {user.search_id &&
          myPhotos.length &&
          !showFavouriteImages &&
          !showFaceSearchImages ? (
            <Button
              disabled={myPhotosLoader}
              text="Download My Photos"
              onClick={downloadFaceSearchImages}
            />
          ) : (
            <></>
          )}
          {showFaceSearchImages && guestUploadedImages.length > 0 && (
            <Button
              disabled={myPhotosLoader}
              text="Download Guest Uploads"
              onClick={downloadGuestUpload}
            />
          )}
        </div>
      </form>
    </div>
  );
}
